
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
  export default class BaseDialog extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };

  @Prop({ type: Boolean, default: false }) grow!: string;
  @Prop({ type: Boolean, default: false }) show!: string;
  @Prop({ type: Number, default: 400 }) width!: number;
  @Prop({ type: Number, default: 0 }) height!: number;
  @Prop({ type: Boolean, default: undefined }) left!: boolean;
  @Prop({ type: Boolean, default: undefined }) right!: boolean;
  @Prop({ type: Boolean, default: undefined }) noWingsLeft!: boolean;

  get isCenter (): boolean {
    return !this.left && !this.right
  }

  get icon (): string {
    if (this.show) {
      if (this.left) {
        return `mdi-arrow-right-circle-outline`
      }
      if (this.right) {
        return `mdi-arrow-left-circle-outline`
      }
    } else {
      if (this.left) {
        return `mdi-arrow-left-circle-outline`
      }

      if (this.right) {
        return `mdi-arrow-right-circle-outline`
      }
    }

    return ''
  }

  get alignment (): string {
    if (this.show) {
      if (this.left) {
        return `end`
      }
      if (this.right) {
        return `start`
      }
    }
    if (this.left) {
      return `end`
    }

    if (this.right) {
      return `start`
    }

    return ''
  }

  submit (): void {
    if (this.$refs.form.validate()) {
      this.$emit('submit')
    }
  }
  }
