
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import BaseDialog from '@/components/core/baseDialog/BaseDialog.vue'
  import {
    DEFAULT_COLOR,
    DEFAULT_ERROR_COLOR,
    TEXT_SIZE_H4,
  } from '@/utils/general'

@Component({
  components: { BaseDialog },
})
  export default class DialogContent extends Vue {
  wingsHeight = 0;
  maxHeight = 400;
  form = false;
  textSubtitle = TEXT_SIZE_H4;
  errors = DEFAULT_ERROR_COLOR;
  colors = DEFAULT_COLOR;
  @Prop({ type: Boolean, default: false }) grow!: boolean;
  @Prop({ type: Boolean, default: true }) show!: boolean;
  @Prop({ type: Boolean, default: undefined }) left!: boolean;
  @Prop({ type: Boolean, default: undefined }) right!: boolean;
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: String, default: '' }) subtitle!: string;
  @Prop({ type: Number, default: 388 }) width!: number;
  @Prop({ type: Number, default: 0 }) height!: number;
  @Prop({ type: Number, default: 0 }) level!: number;
  @Prop({ type: Boolean, default: false }) divisor!: boolean;
  @Prop({ type: Boolean, default: false }) buttonDisabled!: boolean;
  @Prop({ type: Boolean, default: false }) buttonCancelledDisabled!: boolean;
  @Prop({ type: Boolean, default: false }) noWingsLeft!: boolean;

  get percentageValue (): number {
    return this.level * 5
  }

  open (val: { left: boolean; right: boolean }): void {
    this.$emit('button-pressed', val)
  }

  percentageOf (a: number, b: number): number {
    return (a / 100) * b
  }

  mounted (): void {
    this.maxHeight =
      this.$vuetify.breakpoint.height -
      100 -
      this.percentageOf(
        this.percentageValue,
        this.$vuetify.breakpoint.height - 100
      )

    this.wingsHeight = this.height && this.height < this.maxHeight ? this.height - 50 : this.maxHeight - 50
  }

  @Watch('height') onChangeHeight (): void {
    this.wingsHeight = this.height && this.height < this.maxHeight ? this.height - 50 : this.maxHeight - 50
  }
  }
